<template>
  <a-card>
    <div>
      <div class="item-panel">
        <div class="upload-label">银行流水文件：</div>
        <a-upload
          accept=".xlsx,.xls,.csv"
          :multiple="false"
          :showUploadList="false"
          withCredentials
          :action="this.uploadUrl()"
          name="multipartFile"
          @change="uploadWaterExcelUrl"
        >
          <a-button
            :disabled="waterExcelUrl"
            class="upload-button"
            :icon="waterExcelUrl ? 'check' : ''"
            >{{ waterExcelUrl ? "已上传" : "上传" }}</a-button
          >
        </a-upload>
      </div>

      <div class="item-panel">
        <div class="upload-label">商户信息文件：</div>
        <a-upload
          accept=".xlsx,.xls,.csv"
          :multiple="false"
          :showUploadList="false"
          withCredentials
          :action="this.uploadUrl()"
          name="multipartFile"
          @change="uploadMerchantExcelUrl"
        >
          <a-button
            :disabled="merchantExcelUrl"
            class="upload-button"
            :icon="merchantExcelUrl ? 'check' : ''"
            >{{ merchantExcelUrl ? "已上传" : "上传" }}</a-button
          >
        </a-upload>
      </div>

      <div class="item-panel">
        <div class="upload-label">收款单模版：</div>
        <a-upload
          accept=".xlsx,.xls,.csv"
          :multiple="false"
          :showUploadList="false"
          withCredentials
          :action="this.uploadUrl()"
          name="multipartFile"
          @change="uploadReceiptExcelUrl"
        >
          <a-button
            :disabled="receiptExcelUrl"
            class="upload-button"
            :icon="receiptExcelUrl ? 'check' : ''"
            >{{ receiptExcelUrl ? "已上传" : "上传" }}</a-button
          >
        </a-upload>
      </div>
    </div>
    <a-divider />
    <div style="display: flex; align-items: baseline">
      <a-button type="primary" :disabled="submitDisabled" @click="submit()"
        >提交</a-button
      >
      <div v-if="submitDisabled" class="notice">请先上传文件</div>
    </div>
  </a-card>
</template>
<script>

export default {
  name: "jdExcelTool",
  components: {},
  data() {
    return {
      // 银行流水文件url
      waterExcelUrl: null,
      // 商户信息文件url
      merchantExcelUrl: null,
      // 收款单模版文件url
      receiptExcelUrl: null,
    };
  },
  methods: {
    // 上传url
    uploadUrl() {
      return this.$baseUrl + "upload/ossFile";
    },
    // 上传
    uploadExcel(fileList, fileType) {
      if (fileList.length === 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            if (element.response.returnCode == "200") {
              this[fileType] = element.response.obj;
              // fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    uploadWaterExcelUrl({ fileList }) {
      this.uploadExcel(fileList, "waterExcelUrl");
    },
    uploadMerchantExcelUrl({ fileList }) {
      this.uploadExcel(fileList, "merchantExcelUrl");
    },
    uploadReceiptExcelUrl({ fileList }) {
      this.uploadExcel(fileList, "receiptExcelUrl");
    },
    submit() {
      let url =
        this.$baseUrl +
        "tools/jdExcel" +
        `?waterExcelUrl=${this.waterExcelUrl}` +
        `&merchantExcelUrl=${this.merchantExcelUrl}` +
        `&receiptExcelUrl=${this.receiptExcelUrl}`;
      window.open(url, "_blank");
    },
  },
  computed: {
    submitDisabled() {
      return (
        !this.waterExcelUrl || !this.merchantExcelUrl || !this.receiptExcelUrl
      );
    },
  },
};
</script>
<style scoped>
.upload-button {
  margin: 10px;
  width: 100px;
}
.upload-label {
  width: 100px;
}
.item-panel {
  display: flex;
  align-items: baseline;
  margin: 20px;
}
.notice {
  margin-left: 20px;
  color: lightgray;
}
</style>